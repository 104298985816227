
.VolumeSlider input[type=range] {
  width: 100%;
  margin: 0.5px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
.VolumeSlider input[type=range]:focus {
  outline: none;
}
.VolumeSlider input[type=range]::-webkit-slider-runnable-track {
  background: #777777;
  border: 0;
  border-radius: 9.6px;
  width: 100%;
  height: 6px;
  cursor: pointer;
}
.VolumeSlider input[type=range]::-webkit-slider-thumb {
  margin-top: -0.5px;
  width: 1px;
  height: 6px;
  background: #ffffff;
  border: 0px solid #c5c5c5;
  border-radius: 11px;
  cursor: pointer;
  -webkit-appearance: none;
}

.VolumeSlider input[type=range]::-moz-range-track {
  background: #aaaaaa;
  border: 0;
  border-radius: 9.6px;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
.VolumeSlider input[type=range]::-moz-range-thumb {
  width: 25px;
  height: 6px;
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 11px;
  cursor: pointer;
}

progress {
  -webkit-appearance: none;
}
.VolumeSlider input[type="range"]::-moz-range-progress,
.VolumeSlider input[type="range"]::-webkit-progress-value
{
  background-color: #fff;
    height: 100%;
  border:none;
}
.VolumeSlider input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 1.5px 0;
  color: transparent;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
.VolumeSlider input[type=range]::-ms-fill-lower {
  background: #777777;
  border: 0;
  border-radius: 19.2px;
}
.VolumeSlider input[type=range]::-ms-fill-upper {
  background: #777777;
  border: 0;
  border-radius: 19.2px;
}
.VolumeSlider input[type=range]::-ms-thumb {
  width: 25px;
  height: 6px;
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 11px;
  cursor: pointer;
  margin-top: 0px;
}

.VolumeSlider input[type=range]:focus::-ms-fill-lower {
  background: #777777;
}
.VolumeSlider input[type=range]:focus::-ms-fill-upper {
  background: #777777;
}


@supports (-ms-ime-align:auto) {
  
  .VolumeSlider input[type=range] {
    margin: 0;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .VolumeSlider input[type='range'] {
    overflow: hidden;
    -webkit-appearance: none;
    background-color: #9a905d;
  }
  
  .VolumeSlider input[type='range']::-webkit-slider-runnable-track {

    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;
  }
  
  .VolumeSlider input[type='range']::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #ffffff;
    box-shadow: -80px 0 0 80px #ffffff;
  }

}