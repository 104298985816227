:root {
  --search-width: 450px;
}

.SearchModal:before {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,1);
  /* background-image: url('./bg.jpg'); */
  background-size: cover;
  content: ' ';
  display: block;
  opacity: 1;
}

.SearchModal {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  filter: brightness(2.85);
}

.playing .SearchModal {
  display: none;
}

.Search {
  position: fixed;
  top: 50%;
  min-width: var(--search-width);
  max-width: var(--search-width);
  width: var(--search-width);
  z-index: 999;
  left: calc(50% - var(--search-width) / 2);
  color: #efefee;
}

.Search .Search-input {
  width: 100%;
  border: none;
  background: none;
  outline: none;
  opacity: 0.5;
  transition: all 0.1s ease-in-out;
  font-family: 'Inconsolata', monospace;
  font-size:13pt;
  color: #efefee !important;
  text-align: center;
}

.Search .Search-input:focus {
  opacity: 0.7;
  transition: all 0.7s ease-in-out;
} 

::selection {
  background-color: rgba(211, 192, 107, 0.4);
}

.Search-decoration {
  width: 100%;
  margin: auto;
  height: 12px;
  border-bottom: 1px solid;
  opacity: 0.5;
  border-image-source: radial-gradient(
    rgb(223, 248, 246),
    rgba(255, 255, 255, 0.1)
  );
  border-image-slice: 1;
  box-shadow: 0px 13px 60px 0px rgba(255,255,255,0.35);
  transition: opacity 0.8s ease-out;
  transition: width 1.5s ease-in-out;
}

.loading .Search-decoration {
  width: 0%;
  border-image-source: radial-gradient(
    rgb(224, 181, 64),
    rgba(255, 255, 255, 0.1)
  );
  transition: all 2s ease-out;
  transition: opacity 2s ease-out;
  transition: width 1.5s ease-in-out;
}

.Search .Search-input input {
  color: #efefee !important;

}

.Search.valid .Search-input {
  border-image-source: radial-gradient(
    rgba(64, 224, 208, 1),
    rgba(64, 224, 208, 0.3)
  );
}

.Search.invalid .Search-input {
  border-image-source: radial-gradient(
    rgba(255, 103, 0, 1),
    rgba(255, 103, 0, 0.3)
  );
}

.Search-message {
  margin-top: 12px;
  font-size: 9.5pt;
  opacity: 0.7;

  text-transform: uppercase;
}