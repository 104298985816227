.App {
  text-align: center;
  background: black;
}

.App.hideCursor {
  cursor: none;
}

.App-header {
  position: fixed;
  top: 0px;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: rgba(255,255,255,0.8);
  text-transform: uppercase;
  user-select: none;
  transition: all 0.3s;
}
/* 
.App.hideCursor .App-header {
  opacity: 0;
  transition: all 0.3s;
} */

.App:not(.playing) .App-heaqer, .App:not(.playing) .App-text, .App:not(.playing) .App-volume, .App:not(.playing) .App-mfts {
  cursor: none;
  opacity: 0;
  transition: all 0s;
}

.App.hideCursor .App-heaqer, .App.hideCursor .App-text, .App.hideCursor .App-volume, .App.hideCursor .App-mfts {
  cursor: none;
  opacity: 0;
  transition: all 3s;
}

.App-text {
  position:absolute;
  bottom: 20px;
  font-size: 20px;
  margin: auto;
  text-align: center;
  width: 100%;
  pointer-events: none;
  user-select: none;
}

.App-numberage {
  font-size: 15px;
  pointer-events: none;
  user-select: none;
}

.App-link {
  color: #61dafb;
}

.App-volume {
  position: absolute;
  right: 5%;
  bottom: 20px;
}

.App-mfts {
  position: absolute;
  margin: auto;
  width: 100%;
  top: 20px;
  opacity: 0.9;
  font-size: 10px;
  pointer-events: none;
  user-select: none;
}

canvas {
  pointer-events: none;
  user-select: none;
}