@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;500;600&display=swap);
html, body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif; */
  font-family: 'Inconsolata', monospace;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  background: #000000;
  height: 100%;
  width: 100%;
  letter-spacing: 0.08em;
  color: white !important;
}

#root, .App, #threeContainer {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background: black;
}

.App.hideCursor {
  cursor: none;
}

.App-header {
  position: fixed;
  top: 0px;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: rgba(255,255,255,0.8);
  text-transform: uppercase;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: all 0.3s;
}
/* 
.App.hideCursor .App-header {
  opacity: 0;
  transition: all 0.3s;
} */

.App:not(.playing) .App-heaqer, .App:not(.playing) .App-text, .App:not(.playing) .App-volume, .App:not(.playing) .App-mfts {
  cursor: none;
  opacity: 0;
  transition: all 0s;
}

.App.hideCursor .App-heaqer, .App.hideCursor .App-text, .App.hideCursor .App-volume, .App.hideCursor .App-mfts {
  cursor: none;
  opacity: 0;
  transition: all 3s;
}

.App-text {
  position:absolute;
  bottom: 20px;
  font-size: 20px;
  margin: auto;
  text-align: center;
  width: 100%;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App-numberage {
  font-size: 15px;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App-link {
  color: #61dafb;
}

.App-volume {
  position: absolute;
  right: 5%;
  bottom: 20px;
}

.App-mfts {
  position: absolute;
  margin: auto;
  width: 100%;
  top: 20px;
  opacity: 0.9;
  font-size: 10px;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

canvas {
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.VolumeSlider input[type=range] {
  width: 100%;
  margin: 0.5px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
.VolumeSlider input[type=range]:focus {
  outline: none;
}
.VolumeSlider input[type=range]::-webkit-slider-runnable-track {
  background: #777777;
  border: 0;
  border-radius: 9.6px;
  width: 100%;
  height: 6px;
  cursor: pointer;
}
.VolumeSlider input[type=range]::-webkit-slider-thumb {
  margin-top: -0.5px;
  width: 1px;
  height: 6px;
  background: #ffffff;
  border: 0px solid #c5c5c5;
  border-radius: 11px;
  cursor: pointer;
  -webkit-appearance: none;
}

.VolumeSlider input[type=range]::-moz-range-track {
  background: #aaaaaa;
  border: 0;
  border-radius: 9.6px;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
.VolumeSlider input[type=range]::-moz-range-thumb {
  width: 25px;
  height: 6px;
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 11px;
  cursor: pointer;
}

progress {
  -webkit-appearance: none;
}
.VolumeSlider input[type="range"]::-moz-range-progress,
.VolumeSlider input[type="range"]::-webkit-progress-value
{
  background-color: #fff;
    height: 100%;
  border:none;
}
.VolumeSlider input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 1.5px 0;
  color: transparent;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
.VolumeSlider input[type=range]::-ms-fill-lower {
  background: #777777;
  border: 0;
  border-radius: 19.2px;
}
.VolumeSlider input[type=range]::-ms-fill-upper {
  background: #777777;
  border: 0;
  border-radius: 19.2px;
}
.VolumeSlider input[type=range]::-ms-thumb {
  width: 25px;
  height: 6px;
  background: #ffffff;
  border: 1px solid #c5c5c5;
  border-radius: 11px;
  cursor: pointer;
  margin-top: 0px;
}

.VolumeSlider input[type=range]:focus::-ms-fill-lower {
  background: #777777;
}
.VolumeSlider input[type=range]:focus::-ms-fill-upper {
  background: #777777;
}


@supports (-ms-ime-align:auto) {
  
  .VolumeSlider input[type=range] {
    margin: 0;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .VolumeSlider input[type='range'] {
    overflow: hidden;
    -webkit-appearance: none;
    background-color: #9a905d;
  }
  
  .VolumeSlider input[type='range']::-webkit-slider-runnable-track {

    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;
  }
  
  .VolumeSlider input[type='range']::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #ffffff;
    box-shadow: -80px 0 0 80px #ffffff;
  }

}
:root {
  --search-width: 450px;
}

.SearchModal:before {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,1);
  /* background-image: url('./bg.jpg'); */
  background-size: cover;
  content: ' ';
  display: block;
  opacity: 1;
}

.SearchModal {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  -webkit-filter: brightness(2.85);
          filter: brightness(2.85);
}

.playing .SearchModal {
  display: none;
}

.Search {
  position: fixed;
  top: 50%;
  min-width: 450px;
  min-width: var(--search-width);
  max-width: 450px;
  max-width: var(--search-width);
  width: 450px;
  width: var(--search-width);
  z-index: 999;
  left: calc(50% - 450px / 2);
  left: calc(50% - var(--search-width) / 2);
  color: #efefee;
}

.Search .Search-input {
  width: 100%;
  border: none;
  background: none;
  outline: none;
  opacity: 0.5;
  transition: all 0.1s ease-in-out;
  font-family: 'Inconsolata', monospace;
  font-size:13pt;
  color: #efefee !important;
  text-align: center;
}

.Search .Search-input:focus {
  opacity: 0.7;
  transition: all 0.7s ease-in-out;
} 

::selection {
  background-color: rgba(211, 192, 107, 0.4);
}

.Search-decoration {
  width: 100%;
  margin: auto;
  height: 12px;
  border-bottom: 1px solid;
  opacity: 0.5;
  border-image-source: radial-gradient(
    rgb(223, 248, 246),
    rgba(255, 255, 255, 0.1)
  );
  border-image-slice: 1;
  box-shadow: 0px 13px 60px 0px rgba(255,255,255,0.35);
  transition: opacity 0.8s ease-out;
  transition: width 1.5s ease-in-out;
}

.loading .Search-decoration {
  width: 0%;
  border-image-source: radial-gradient(
    rgb(224, 181, 64),
    rgba(255, 255, 255, 0.1)
  );
  transition: all 2s ease-out;
  transition: opacity 2s ease-out;
  transition: width 1.5s ease-in-out;
}

.Search .Search-input input {
  color: #efefee !important;

}

.Search.valid .Search-input {
  border-image-source: radial-gradient(
    rgba(64, 224, 208, 1),
    rgba(64, 224, 208, 0.3)
  );
}

.Search.invalid .Search-input {
  border-image-source: radial-gradient(
    rgba(255, 103, 0, 1),
    rgba(255, 103, 0, 0.3)
  );
}

.Search-message {
  margin-top: 12px;
  font-size: 9.5pt;
  opacity: 0.7;

  text-transform: uppercase;
}
